import { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import Collapse from 'react-bootstrap/Collapse';
import styled from '../../../../styles/module/transparency/actosSubtype.module.css'
import ButtonTypeHidden from './../utils/ButtonTypeHidden';
 

export default function TableSubtype({atos,subtipo,key}) {
  const [showBox, setShowBox] = useState(false)
  const [mouseEnterUnique, setMouseEnterUnique] = useState(0)
  const [countResult, setCountResult] = useState(0)
  const [dataSubtype, setDataSubtype] = useState([])

  let countMAP = []

  useEffect(()=>{
    //para abrir a tabela apenas uma vez quando o mouse estiver em cima (primeira vez apenas)
   
    if(mouseEnterUnique == 1){
      setShowBox(!showBox)
    }
  },[mouseEnterUnique])


  // useEffect(()=>{
  
  //   console.log(countResult)
  // },[countResult])


  const count = (data)=>{
    // setCountResult(countResult + 1)
    console.log(data)
  }
 
  
return(
  <>
     {atos != undefined ? <>
      <section  
      onMouseEnter={() => { setMouseEnterUnique(mouseEnterUnique+1)}}
      onClick={() => {setShowBox(!showBox)} }
      title="Click para abrir ou fechar a tabela"
      className={styled.titleSub}
      key={key}
      >
       

      <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 24 24" className={styled.svgSubtype}>
      <polygon points="7.293 4.707 14.586 12 7.293 19.293 8.707 20.707 17.414 12 8.707 3.293 7.293 4.707"/>
      </svg>

    
      <h5>{subtipo}</h5>
      </section>
        
        <Collapse in={showBox}>
        
      
        <div id="example-collapse-text">
              <Table responsive>
      <thead>
          <tr>
            <th className="B-portarias-th">Data</th>
            <th className="B-portarias-th">Titulo</th>
            <th className="B-portarias-th">Ações</th>
          </tr>
        </thead>
          <tbody>
              {atos.map((data, index) => {
                
              

                if(data.SUBTIPO == subtipo){
                  countMAP.push(data)
                  console.log(countMAP , subtipo)
                  return(
                    <>
                      <tr key={index} >
                        <td className="data-td-portais portarias-td">{data.DATA}</td>
                        <td className="title-td-portais portarias-td">
                        <ButtonTypeHidden
                        link={"http://www.transparenciadministrativa.com.br/portalimagem/"+data.CNPJ.replace('/','').replace('.','').replace('.','').replace('-','')+'/'+data.ARQUIVO}
                        targetBlank={true}
                        >
                          {data.TITULO}
                        </ButtonTypeHidden>
                          </td>
                        <td className="acoes-td-portais portarias-td">
                        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" className="icon-download-DOWNLOAD" viewBox="0 0 16 16">
                        <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z"/>
                        <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z"/>
                        </svg>    
                      
                        </td>
                      </tr>
                    </>
  )
                }
})}
              </tbody>
              </Table>
                {countMAP.length == 0 && <><h6 className="resultTxt ordinances-result">Nenhum Resultado Encontrado</h6></>}
                {countMAP.length == 1 && <><div className="resultTxt ordinances-result"></div></>}
              </div>
                </Collapse> 
                </> : <></>}
              {/*  )})}</> : <></>}       */}

</>

    // </>
  );
}


